import React from 'react'

function StyledCursor() {
  return (
    // The cursor elements 
    <div class="df-custom-cursor-wrap">
      <div id="df-custom-cursor"></div>
    </div>
  )
}

export { StyledCursor }