import React from 'react'

function HomeContent() {
  return (
    <>
      <main className="site-content text-center" id="wrapper">
        <div className="site-content__center">
          <h1 className="text-white landing-title">
              <span className="subtitle landing-subtitle-text mb-4 ">
                COMING SOON
              </span>
            THE EVOLUTION OF GAMING
          </h1>
          <span>
            <h3 className="text-white  ">
              EVOLVE COIN
            </h3>
          </span>
          <span className='text-white' >To get more info about the project and get involved in our ICO, please visit <a className='link-button' href="https://evolvecoin.io/">EvolveCoin.io</a></span>
        </div>
      </main>
    </>
  )
}

export { HomeContent }
