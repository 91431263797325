import React from 'react'

function HomeFooter() {
  return (
    <>
      <footer id="footer" className=" text-center mb-5 mt-3">
        <ul className="social-menu social-menu--landing social-menu--landing-glitch">
          <li>
            <a href="https://www.instagram.com/evolvecoin/" target="_blank">
              <i className="fab fa-instagram" />
              <span className="link-subtitle">Instagram</span>EVOLVECOIN INSTAGRAM
            </a>
          </li>
          <li>
            <a href="https://twitter.com/EVOLVE_coin" target="_blank">
              <i className="fab fa-twitter" />
              <span className="link-subtitle">Twitter</span>EVOLVECOIN TWITTER
            </a>
          </li>
           <li>
            <a href="https://discord.com/invite/K8hFuGY63y" target="_blank">
              <i className="fab fa-discord" />
              <span className="link-subtitle">Discord</span>EVOLVECOIN DISCORD
            </a>
          </li>
          {/* <li>
            <a href="https://linkedin.com/in/evolvecoin" target="_blank">
              <i className="fab fa-linkedin" />
              <span className="link-subtitle">Linkedin</span>EVOLVECOIN LINKEDIN
            </a>
          </li> */}
          {/* <li>
            <a href="https://www.reddit.com/user/EVOLVE_coin/" target="_blank">
              <i className="fab fa-reddit" />
              <span className="link-subtitle">REDDIT</span>EVOLVECOIN REDDIT
            </a>
          </li> */}
          <li>
            <a href="https://fb.me/EVOLVEcoinOfficial" target="_blank">
              <i className="fab fa-facebook" />
              <span className="link-subtitle">FACEBOOK</span>EVOLVECOIN FACEBOOK
            </a>
          </li>

        </ul>
      </footer>
    </>
  )
}

export { HomeFooter }
