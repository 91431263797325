import { Home } from './home/Home'
import './styles/global.css'

function App() {

  return (
      <Home />
  );
}

export default App;
