import React, { useEffect } from 'react'
import { Preloader } from './components/Preloader'
import { HomeLayout } from '../home-layout/HomeLayout'
import { HomeContent } from './components/HomeContent'
import { StyledCursor } from './components/StyledCursor'

function Home() {

  useEffect(() => {
    customCursor()
  }, [])

  const customCursor = () => {
    let $ = document.querySelector.bind(document);
    let $on = document.addEventListener.bind(document);
    let xmouse, ymouse;
    $on('mousemove', function (e) {
      xmouse = e.clientX || e.pageX;
      ymouse = e.clientY || e.pageY;
    });
    let ball = $('#df-custom-cursor');
    let x = void 0,
      y = void 0,
      dx = void 0,
      dy = void 0;
    let followMouse = function followMouse() {
      requestAnimationFrame(followMouse);
      if (!x || !y) {
        x = xmouse;
        y = ymouse;
      } else {
        dx = (xmouse - x) * 0.25;
        dy = (ymouse - y) * 0.25;
        if (Math.abs(dx) + Math.abs(dy) < 0.1) {
          x = xmouse;
          y = ymouse;
        } else {
          x += dx;
          y += dy;
        }
      }
      ball.style.transform = "translate(" + x + "px, " + y + "px)";
    };
    followMouse();
  }

  return (
    <HomeLayout>
      <HomeContent />
      <Preloader />
      <StyledCursor />
    </HomeLayout >
  )
}
export { Home }
