import React from 'react'

function Preloader() {
  return (
    <div className="preloader-overlay">
      <div id="js-preloader" className="preloader">
        <div className="preloader-inner fadeInUp">
          <div className="pong-loader" />
          <svg role="img" className="df-icon df-icon--preloader-arcade">
            <use xlinkHref="assets/img/necromancers.svg#preloader-arcade" />
          </svg>
        </div>
      </div>
    </div>
  )
}

export { Preloader }
